<template>
<div class="container">
  <div>
    <div class="top" id="bloc-0">
      <voice-header @login-success="handleLoginSuccess" @login-error="handleLoginError" @logout="handleLogout"></voice-header>
    </div>
    <div class="pay_container">
        <svg class="pay_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#e74c3c" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="15" y1="9" x2="9" y2="15"></line>
            <line x1="9" y1="9" x2="15" y2="15"></line>
        </svg>
        <h1>{{ $t('pricing.pay_fail') }}</h1>
        <p>{{ $t('pricing.pay_fail_desc') }}</p>
        <a :href="home_href" class="pay_cancel_btn">{{ $t('pricing.pay_fail_btntext') }}</a>
    </div>
  </div>
</div>
</template>

<script>
import VoiceHeader from '../components/VoiceHeader.vue';
import VoiceFooter from '../components/VoiceFooter.vue';

export default {
  name: 'CancelPage',
  data() {
    return {
      home_href: '/'
    }
  },
  head() {
    return {
      'title': this.$i18n.t('txt2voice.title'),
      'keywords': this.$i18n.t('txt2voice.keywords'),
      'description': this.$i18n.t('txt2voice.description'),
    }
  },
  components: {
    VoiceHeader,
    VoiceFooter
  },
  asyncData: function ({ store, route }) {
    // console.log('home state count:' + store.state.count)
    return store.dispatch("fetchData")
  },
  mounted () {
    if (this.$store.state.lang !== 'en') {
      this.home_href = '/' + this.$store.state.lang  
    }
  }
}
</script>
